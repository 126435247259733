import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~2],
		"/account": [4],
		"/account/[lang]": [5],
		"/coins/[id]": [~6],
		"/coins/[id]/[lang]": [7],
		"/contact-us": [8],
		"/contact-us/[lang]": [9],
		"/exchanges": [~10],
		"/exchanges/[lang=lang]": [12],
		"/exchanges/[exchange]": [~11],
		"/learn": [~13],
		"/learn/glossary": [~16],
		"/learn/glossary/[lang=lang]": [~17],
		"/learn/glossary/[term]": [~18],
		"/learn/[lang=lang]": [~14],
		"/learn/[slug]": [~15],
		"/markets/[exchange]": [~19],
		"/markets/[exchange]/[lang]": [20],
		"/newsletter": [24],
		"/newsletter/settings": [25],
		"/newsletter/settings/[lang]": [26],
		"/news": [~21],
		"/news/[lang=lang]": [~22],
		"/news/[slug]": [~23],
		"/portfolio": [27],
		"/portfolio/history": [29],
		"/portfolio/history/[lang]": [30],
		"/portfolio/[lang]": [28],
		"/preview": [~31],
		"/privacy-policy": [32],
		"/privacy-policy/[lang]": [33],
		"/reset-password/[slug]": [34],
		"/terms-of-service": [35],
		"/terms-of-service/[lang]": [36],
		"/watchlist": [37],
		"/watchlist/[lang]": [38],
		"/[lang]": [3]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';