// https://github.com/getsentry/sentry-javascript/tree/develop/packages/sveltekit
import * as Sentry from '@sentry/sveltekit';

Sentry.init({
	dsn: import.meta.env.VITE_SENTRY_DSN,
	release: import.meta.env.VERCEL_GIT_COMMIT_SHA || '',
	// integrations: [new Sentry.BrowserTracing()],
	// tracesSampleRate: 1.0,
	// allowUrls: [/.*\.cryptomarketcap\.(com|dev)/],
	// For instance, initialize Session Replay:
	// replaysSessionSampleRate: 0.1,
	// replaysOnErrorSampleRate: 1.0,
	// integrations: [new Sentry.Replay()],
});

const myErrorHandler = (({ error, event }) => {
	console.error('An error occurred on the client side:', error, event);
});

export const handleError = Sentry.handleErrorWithSentry(myErrorHandler);
// or alternatively, if you don't have a custom error handler:
// export const handleError = handleErrorWithSentry();
